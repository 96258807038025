<template>
<v-app id="inspire">
    <div class="px-4">
        <loading :is-show="loadingDialog" :is-done="loadingFinished" :is-html="isHtml" :message="loadingMessage" v-on:close-dialog="closeDialog" />
        <details-form :is-show="isShowDetails" :details="details" v-on:close-dialog="closeDialog" />
        <v-container class="container--fluid grid-list-md text-center">
            <h1>Bills</h1>
        </v-container>
        <v-card elevation="2" outlined shaped tile class="pa-1">
            <div class="d-flex flex-no-wrap justify-space-between" v-if="hasPermission('ROLE_TDM_VIEW_MERCHANT')">
                <v-card-title>
                    <v-container grid-list-md text-xs-center>
                        <v-layout row wrap>
                            <v-flex xs12 sm6>
                                <v-text-field label="Bill number" v-model="query.billingNumber" single-line outlined @change="search($event, 'billingNumber')" data-cy="module-billing-number"></v-text-field>
                            </v-flex>
                            <v-flex xs12 sm6>
                                <v-text-field label="Account number" v-model="query.accountNumber" single-line outlined @change="search($event, 'accountNumber')" data-cy="module-account-number"></v-text-field>
                            </v-flex>
                            <v-flex xs12 sm12>
                                <v-text-field label="Account name" v-model="query.accountName" single-line outlined @change="search($event, 'accountName')" data-cy="module-account-name"></v-text-field>
                            </v-flex>
                            <v-flex xs6 sm6>
                                <v-container fluid>
                                    <v-row align="center">
                                        <v-col cols="3">
                                            <v-subheader>
                                            Issue date: 
                                            </v-subheader>
                                        </v-col>
                                        <v-col cols="9">
                                            <v-row align="center">
                                                <v-col cols="6" md="5">
                                                    <v-menu v-model="fromDateMenuIssue" :close-on-content-click="false" :nudge-right="40" transition="scale-transition" offset-y max-width="290px" min-width="290px" data-cy="date-menu-from-issue">
                                                        <template v-slot:activator="{ on }">
                                                            <v-text-field label="From" prepend-icon="mdi-calendar" readonly :value="fromDateDispIssue" v-on="on" data-cy="date-menu-from-issue"></v-text-field>
                                                        </template>
                                                        <v-date-picker locale="en-in" v-model="query.startIssueDate" no-title @input="fromDateMenuIssue = false" :min="minDate" :max="fromMaxIssue" data-cy="date-menu-from-issue">
                                                            <v-btn text color="primary" @click="clearFromDate('issue')">Clear
                                                            </v-btn>
                                                        </v-date-picker>
                                                    </v-menu>
                                                    </v-col>
                                                    <v-col cols="6" md="5">
                                                    <v-menu ref="menu" v-model="toDateMenuIssue" :close-on-content-click="false" :nudge-right="40" transition="scale-transition" offset-y max-width="290px" min-width="290px" data-cy="date-menu-to-issue">
                                                        <template v-slot:activator="{ on }">
                                                            <v-text-field label="To" prepend-icon="mdi-calendar" readonly :value="toDateDispIssue" v-on="on" data-cy="date-menu-to-issue"></v-text-field>
                                                        </template>
                                                        <v-date-picker locale="en-in" v-model="query.endIssueDate" no-title @input="toDateMenuIssue = false" :max="maxDate" :min="toMinIssue" data-cy="date-menu-to-issue">
                                                            <v-btn text color="primary" @click="clearToDate('issue')" data-cy="button-clear">Clear
                                                            </v-btn>
                                                        </v-date-picker>
                                                    </v-menu>
                                                </v-col>
                                            </v-row>
                                        </v-col>
                                    </v-row>
                                </v-container>
                            </v-flex>
                            <v-flex xs6 sm6>
                                <v-container fluid>
                                    <v-row align="center">
                                        <v-col cols="3">
                                            <v-subheader>
                                            Due date: 
                                            </v-subheader>
                                        </v-col>
                                        <v-col cols="9">
                                            <v-row align="center">
                                                <v-col cols="6" md="5">
                                                    <v-menu v-model="fromDateMenuDue" :close-on-content-click="false" :nudge-right="40" transition="scale-transition" offset-y max-width="290px" min-width="290px" data-cy="date-menu-from-due">
                                                        <template v-slot:activator="{ on }">
                                                            <v-text-field label="From" prepend-icon="mdi-calendar" readonly :value="fromDateDispDue" v-on="on" data-cy="date-menu-from-due"></v-text-field>
                                                        </template>
                                                        <v-date-picker locale="en-in" v-model="query.startDueDate" no-title @input="fromDateMenuDue = false" :min="minDate" :max="fromMaxDue" data-cy="date-menu-from-due">
                                                            <v-btn text color="primary" @click="clearFromDate('due')">Clear
                                                            </v-btn>
                                                        </v-date-picker>
                                                    </v-menu>
                                                    </v-col>
                                                    <v-col cols="6" md="5">
                                                    <v-menu ref="menu" v-model="toDateMenuDue" :close-on-content-click="false" :nudge-right="40" transition="scale-transition" offset-y max-width="290px" min-width="290px" data-cy="date-menu-to-due">
                                                        <template v-slot:activator="{ on }">
                                                            <v-text-field label="To" prepend-icon="mdi-calendar" readonly :value="toDateDispDue" v-on="on" data-cy="date-menu-to-due"></v-text-field>
                                                        </template>
                                                        <v-date-picker locale="en-in" v-model="query.endDueDate" no-title @input="toDateMenuDue = false" :max="maxDate" :min="toMinDue" data-cy="date-menu-to-due">
                                                            <v-btn text color="primary" @click="clearToDate('due')">Clear
                                                            </v-btn>
                                                        </v-date-picker>
                                                    </v-menu>
                                                </v-col>
                                            </v-row>
                                        </v-col>
                                    </v-row>
                                </v-container>
                            </v-flex>           
                        </v-layout>
                    </v-container>
                </v-card-title>
            </div>
            <div class="d-flex flex-no-wrap justify-space-between">
                <v-card-title>
                    <v-container grid-list-md text-xs-center>
                        <v-layout wrap>
                            <v-flex xs12 sm6 v-if="hasPermission('ROLE_TDM_VIEW_MERCHANT')">
                                <v-btn :block="$vuetify.breakpoint.mdAndDown" class="mt-2" color=success @click="queryData(true)" data-cy="button-search">
                                    <v-icon dark left> mdi-magnify</v-icon> Search
                                </v-btn>
                            </v-flex>
                            <v-flex xs12 sm6 v-if="hasPermission('ROLE_TDM_VIEW_MERCHANT')">
                                <v-btn :block="$vuetify.breakpoint.mdAndDown" class="mt-2" color=default @click="clearSearch()" data-cy="button-clear">
                                    <v-icon dark left> mdi-magnify</v-icon> Clear
                                </v-btn>
                            </v-flex>
                            <!-- <v-flex xs12 sm6 v-if="hasPermission('ROLE_TDM_CREATE_MERCHANT')">
                                <v-btn :block="$vuetify.breakpoint.mdAndDown" class="mt-2" color="success" @click.stop="showBillForm('CREATED')">Create Bill</v-btn>
                                <merchant-form ref="merchantForm" :selected-item="selectedBill" :merchantCategoryCodes="merchantCategoryCodes" :countries="countries" :is-show="showFormDialog" :form-type="merchantFormType" :paymentAgents="paymentAgents" :isNotPaymentAgent=isNotPaymentAgent v-on:close-dialog="closeDialog" v-on:save-merchant="saveBill" />
                            </v-flex> -->
                        </v-layout>
                    </v-container>
                </v-card-title>
            </div>
        </v-card>
        <template>
            <v-card>
                <v-tabs
                v-model="tab"
                background-color="transparent"
                grow
                >
                    <v-tab>
                        DUE BILLS
                    </v-tab>
                    <v-tab>
                        OVERDUE BILLS
                    </v-tab>
                    <v-tab>
                        PAID BILLS
                    </v-tab>
                </v-tabs>
                <v-tabs-items v-model="tab">
                    <v-tab-item>
                        <v-data-table :hide-default-header="true" :headers="headers" :items="displayBillsPending" :loading="loading" :page="pagePending" :pageCount="numberOfPagesPending" :options.sync="options" :server-items-length="totalBillsPending" :footer-props="{'items-per-page-options': [10]}" class="elevation-2 mt-4 px-4">
                            <template v-slot:header="{ props: { headers } }">
                                <thead>
                                    <tr :colspan="headers.length">
                                        <th v-for="header in headers" :key="header.text">
                                            <span class="d-flex justify-center">{{header.text}}</span>
                                        </th>
                                    </tr>
                                </thead>
                            </template>
                            <template v-slot:body="{ items }">
                                <tbody>
                                    <tr :colspan="headers.length" v-for="item in items" :key="item.id">
                                        <td> <span class="d-flex justify-center"> {{ item.billingNumber }} </span></td>
                                        <td> <span class="d-flex justify-center"> {{ item.accountNumber }} </span></td>
                                        <td> <span class="d-flex justify-center"> {{ item.accountName }} </span></td>
                                        <td> <span class="d-flex justify-center"> {{ item.billAmount }} </span></td>
                                        <td> <span class="d-flex justify-center"> {{ item.issueDate }} </span></td>
                                        <td> <span class="d-flex justify-center"> {{ item.dueDate }} </span></td>
                                        <!-- <td>
                                            <span class="d-flex justify-center">
                                                <v-btn fab dark x-small color="green" class="mr-2" @click.stop="viewBill(item)">
                                                    <v-icon dark medium>mdi-magnify-plus</v-icon>
                                                </v-btn>
                                                <v-btn fab dark x-small color="green" @click.stop="showBillForm('UPDATE', item)" v-if="hasPermission('ROLE_TDM_UPDATE_MERCHANT')">
                                                    <v-icon dark medium>mdi-pencil</v-icon>
                                                </v-btn>
                                            </span>
                                        </td> -->
                                    </tr>
                                </tbody>
                            </template>
                            <template v-slot:no-results>
                                <v-alert :value="true" color="error" icon="warning">
                                    Found no results.
                                </v-alert>
                            </template>
                        </v-data-table>
                    </v-tab-item>
                    <v-tab-item>
                        <v-data-table :hide-default-header="true" :headers="headers" :items="displayBillsOverdue" :loading="loading" :page="pageOverdue" :pageCount="numberOfPagesOverdue" :options.sync="options" :server-items-length="totalBillsOverdue" :footer-props="{'items-per-page-options': [10]}" class="elevation-2 mt-4 px-4">
                            <template v-slot:header="{ props: { headers } }">
                                <thead>
                                    <tr :colspan="headers.length">
                                        <th v-for="header in headers" :key="header.text">
                                            <span class="d-flex justify-center">{{header.text}}</span>
                                        </th>
                                    </tr>
                                </thead>
                            </template>
                            <template v-slot:body="{ items }">
                                <tbody>
                                    <tr :colspan="headers.length" v-for="item in items" :key="item.id">
                                        <td> <span class="d-flex justify-center"> {{ item.billingNumber }} </span></td>
                                        <td> <span class="d-flex justify-center"> {{ item.accountNumber }} </span></td>
                                        <td> <span class="d-flex justify-center"> {{ item.accountName }} </span></td>
                                        <td> <span class="d-flex justify-center"> {{ item.billAmount }} </span></td>
                                        <td> <span class="d-flex justify-center"> {{ item.issueDate }} </span></td>
                                        <td> <span class="d-flex justify-center"> {{ item.dueDate }} </span></td>
                                        <!-- <td>
                                            <span class="d-flex justify-center">
                                                <v-btn fab dark x-small color="green" class="mr-2" @click.stop="viewBill(item)">
                                                    <v-icon dark medium>mdi-magnify-plus</v-icon>
                                                </v-btn>
                                                <v-btn fab dark x-small color="green" @click.stop="showBillForm('UPDATE', item)" v-if="hasPermission('ROLE_TDM_UPDATE_MERCHANT')">
                                                    <v-icon dark medium>mdi-pencil</v-icon>
                                                </v-btn>
                                            </span>
                                        </td> -->
                                    </tr>
                                </tbody>
                            </template>
                            <template v-slot:no-results>
                                <v-alert :value="true" color="error" icon="warning">
                                    Found no results.
                                </v-alert>
                            </template>
                        </v-data-table>
                    </v-tab-item>
                    <v-tab-item>
                        <v-data-table :hide-default-header="true" :headers="headers" :items="displayBillsPaid" :loading="loading" :page="pagePaid" :pageCount="numberOfPagesPaid" :options.sync="options" :server-items-length="totalBillsPaid" :footer-props="{'items-per-page-options': [10]}" class="elevation-2 mt-4 px-4">
                            <template v-slot:header="{ props: { headers } }">
                                <thead>
                                    <tr :colspan="headers.length">
                                        <th v-for="header in headers" :key="header.text">
                                            <span class="d-flex justify-center">{{header.text}}</span>
                                        </th>
                                    </tr>
                                </thead>
                            </template>
                            <template v-slot:body="{ items }">
                                <tbody>
                                    <tr :colspan="headers.length" v-for="item in items" :key="item.id">
                                        <td> <span class="d-flex justify-center"> {{ item.billingNumber }} </span></td>
                                        <td> <span class="d-flex justify-center"> {{ item.accountNumber }} </span></td>
                                        <td> <span class="d-flex justify-center"> {{ item.accountName }} </span></td>
                                        <td> <span class="d-flex justify-center"> {{ item.billAmount }} </span></td>
                                        <td> <span class="d-flex justify-center"> {{ item.issueDate }} </span></td>
                                        <td> <span class="d-flex justify-center"> {{ item.dueDate }} </span></td>
                                        <!-- <td>
                                            <span class="d-flex justify-center">
                                                <v-btn fab dark x-small color="green" class="mr-2" @click.stop="viewBill(item)">
                                                    <v-icon dark medium>mdi-magnify-plus</v-icon>
                                                </v-btn>
                                                <v-btn fab dark x-small color="green" @click.stop="showBillForm('UPDATE', item)" v-if="hasPermission('ROLE_TDM_UPDATE_MERCHANT')">
                                                    <v-icon dark medium>mdi-pencil</v-icon>
                                                </v-btn>
                                            </span>
                                        </td> -->
                                    </tr>
                                </tbody>
                            </template>
                            <template v-slot:no-results>
                                <v-alert :value="true" color="error" icon="warning">
                                    Found no results.
                                </v-alert>
                            </template>
                        </v-data-table>
                    </v-tab-item>
                </v-tabs-items>
            </v-card>
        </template>
    </div>
</v-app>
</template>

<script>
import API from '@/api/API.js'
import Loading from '@/views/components/Loading.vue'
// import BillForm from '@/views/components/BillForm.vue'
import DetailsForm from '@/views/components/DetailsForm.vue'
import moment from 'moment';
import {
    mapGetters
} from 'vuex';

export default {
    name: 'Bills',
    components: {
        Loading,
        // BillForm,
        DetailsForm
    },
    data() {
        return {
            headers: [],
            displayBillsPending: [],
            displayBillsPaid: [],
            displayBillsOverdue: [],
            remainderBillsPending: [],
            remainderBillsPaid: [],
            remainderBillsOverdue: [],
            paymentAgents: [],
            isNotPaymentAgent: false,
            countries: [],
            merchantCategoryCodes: [],
            loading: true,
            loadingMessage: "",
            loadingDialog: false,
            loadingFinished: false,
            merchantFormType: 'CREATE',
            selectedBill: {},
            pagePending: 1,
            pagePaid: 1,
            pageOverdue: 1,
            numberOfPagesPending: 0,
            numberOfPagesPaid: 0,
            numberOfPagesOverdue: 0,
            totalBillsPending: 0,
            totalBillsPaid: 0,
            totalBillsOverdue: 0,
            options: {
                rowsPerPage: 10
            },
            details: {
                title: '',
                item: {}
            },
            query: {
                // id: '',
                // name: '',
                // mobileNumber: '',
                // email: '',
                // merchantId: '',
                // enabled: '',
                // dateFrom: '',
                // dateTo: ''

                accountNumber: '',
                billingNumber: '',
                accountName: '',
                startIssueDate: '',
                endIssueDate: '',
                startDueDate: '',
                endDueDate: '',
            },
            fromDateMenuIssue: false,
            fromDateValIssue: "",
            toDateMenuIssue: false,
            toDateValIssue: "",
            fromDateMenuDue: false,
            fromDateValDue: "",
            toDateMenuDue: false,
            toDateValDue: "",
            minDate: "1997-01-01",
            maxDate: "",
            fromMaxIssue: "",
            fromMaxDue: "",
            toMinIssue: "",
            toMinDue: "",
            items: [
                '',
                'true',
                'false'
                ],
            isShowDetails: false,
            showFormDialog: false,
            isHtml: false,
            tab: null
            }
    },
    watch: {
        options: {
            handler() {
                console.log('1')
                this.queryData()
                this.setData()
            },
        },
        deep: true,
    },
    computed: {
        //get role attributes
        ...mapGetters([
            'attributes',
            'merchant',
            'authorities'
        ]),
        fromDateDispIssue() {
            this.toMinIssue = moment().format(this.query.startIssueDate)
            return this.query.startIssueDate;
        },
        fromDateDispDue() {
            this.toMinDue = moment().format(this.query.startDueDate)
            return this.query.startDueDate;
        },
        toDateDispIssue() {
            this.fromMaxIssue = moment().format(this.query.endIssueDate)
            return this.query.endIssueDate; 
        },
        toDateDispDue() {
            this.fromMaxDue = moment().format(this.query.endDueDate)
            return this.query.endDueDate;
        }
    },
    async mounted() {
        this.setHeaders()
        this.setDefaultDateRange()
        //this.queryData()
    },
    methods: {
        hasPermission(authority) {
          if(this.authorities.some(x => x.name === authority)){
            return true
          }
          return false
        },
        async queryData(isSearch = false) {
            this.loading = true
            if (isSearch) {
                this.options.page = 1
            }
            const {
                page,
                itemsPerPage
            } = this.options;
            let pageNumber = page - 1;
            try {
                const limit = itemsPerPage
                const transactionStatus = this.tab === 0 ? 'PENDING' : this.tab === 1 ? 'POSTED' : 'PAID'
                const billListResponse = await API.searchBills(pageNumber, limit, this.query, transactionStatus)
                console.log(JSON.stringify(billListResponse))
                if (!billListResponse || billListResponse.error) {
                    //error getting data
                    console.log(`${billListResponse.error}`)
                } else {
                    var filteredList = billListResponse
                    if (transactionStatus === 'PENDING') {
                        if (page > 1 && this.remainderBillsPending.length > 0) {
                        filteredList = this.remainderBillsPending.concat(filteredList)
                        }
                        this.totalBillsPending = page > 1 ? (pageNumber * itemsPerPage) + filteredList.length : filteredList.length
                        const end = filteredList.length < itemsPerPage ? filteredList.length : itemsPerPage
                        this.displayBillsPending = filteredList.slice(0, end)
                        console.log("this.displayBills: " , this.displayBillsPending, this.totalBillsPending);
                        this.remainderBillsPending = filteredList.length < itemsPerPage ? [] : filteredList.slice(end)
                        this.numberOfPagesPending = this.totalBillsPending / itemsPerPage
                        const hasMoreResponse = await API.searchBills(this.totalBillsPending + 1, 1, this.query, transactionStatus)
                        if (!hasMoreResponse || hasMoreResponse.error) {
                            //error getting data
                            console.log(`${hasMoreResponse.error}`)
                        } else {
                            var hasMoreList = hasMoreResponse.merchants
                            if(hasMoreList.length > 0) {
                            this.totalBillsPending = this.totalBillsPending + 1
                            this.numberOfPagesPending = this.numberOfPagesPending + 1
                            }
                        }
                    }
                    else if (transactionStatus === 'PAID') {
                        if (page > 1 && this.remainderBillsPaid.length > 0) {
                        filteredList = this.remainderBillsPaid.concat(filteredList)
                        }
                        this.totalBillsPaid = page > 1 ? (pageNumber * itemsPerPage) + filteredList.length : filteredList.length
                        const end = filteredList.length < itemsPerPage ? filteredList.length : itemsPerPage
                        this.displayBillsPaid = filteredList.slice(0, end)
                        console.log("this.displayBills: " , this.displayBillsPaid, this.totalBillsPaid);
                        this.remainderBillsPaid = filteredList.length < itemsPerPage ? [] : filteredList.slice(end)
                        this.numberOfPagesPaid = this.totalBillsPaid / itemsPerPage
                        const hasMoreResponse = await API.searchBills(this.totalBillsPaid + 1, 1, this.query, transactionStatus)
                        if (!hasMoreResponse || hasMoreResponse.error) {
                            //error getting data
                            console.log(`${hasMoreResponse.error}`)
                        } else {
                            var hasMoreList = hasMoreResponse.merchants
                            if(hasMoreList.length > 0) {
                            this.totalBillsPaid = this.totalBillsPaid + 1
                            this.numberOfPagesPaid = this.numberOfPagesPaid + 1
                            }
                        }
                    }
                    else {
                        if (page > 1 && this.remainderBillsOverdue.length > 0) {
                        filteredList = this.remainderBillsOverdue.concat(filteredList)
                        }
                        this.totalBillsOverdue = page > 1 ? (pageNumber * itemsPerPage) + filteredList.length : filteredList.length
                        const end = filteredList.length < itemsPerPage ? filteredList.length : itemsPerPage
                        this.displayBillsOverdue = filteredList.slice(0, end)
                        console.log("this.displayBills: " , this.displayBillsOverdue, this.totalBillsOverdue);
                        this.remainderBillsOverdue = filteredList.length < itemsPerPage ? [] : filteredList.slice(end)
                        this.numberOfPagesOverdue = this.totalBillsOverdue / itemsPerPage
                        const hasMoreResponse = await API.searchBills(this.totalBillsOverdue + 1, 1, this.query, transactionStatus)
                        if (!hasMoreResponse || hasMoreResponse.error) {
                            //error getting data
                            console.log(`${hasMoreResponse.error}`)
                        } else {
                            var hasMoreList = hasMoreResponse.merchants
                            if(hasMoreList.length > 0) {
                            this.totalBillsOverdue = this.totalBillsOverdue + 1
                            this.numberOfPagesOverdue = this.numberOfPagesOverdue + 1
                            }
                        }
                    }
                }
                this.loading = false
            } catch (e) {
                console.log(e)
                this.loading = false
            }
        },
        async setData() {
            try {
                const listResponse = await API.getAllCountries()
                if (!listResponse || listResponse.error) {
                    //error getting data
                    console.log(`${listResponse.error}`)
                } else {
                    this.countries = listResponse.countries
                }
            } catch (e) {
                console.log(e)
            }
            try {
                const listResponse = await API.getAllBillCategoryCodes()
                if (!listResponse || listResponse.error) {
                    //error getting data
                    console.log(`${listResponse.error}`)
                } else {
                    this.merchantCategoryCodes = listResponse.merchantCategoryCodes
                }
            } catch (e) {
                console.log(e)
            }
            try {
                const listResponse = await API.getAllPaymentAgents()
                if (!listResponse || listResponse.error) {
                    //error getting data
                    console.log(`${listResponse.error}`)
                } else {
                    this.paymentAgents = listResponse.paymentAgent
                }
            } catch (e) {
                console.log(e)
            }
        },
        setHeaders() {
            var headers = [{
                    text: 'Bill Number',
                    value: 'billingNumber'
                },
                {
                    text: 'Account Number',
                    value: 'accountNumber'
                },
                {
                    text: 'Account Name',
                    value: 'accountName'
                },
                {
                    text: 'Amount',
                    value: 'billAmount'
                },
                {
                    text: 'Issue Date',
                    value: 'issueDate'
                },
                {
                    text: 'Due Date',
                    value: 'dueDate'
                },
            ]
            this.headers = headers
        },
        showBillForm(what, item = {}) {
            this.merchantFormType = what
            this.selectedBill = item
            this.showFormDialog = true
        },
        closeDialog(type) {
            if (type === 'details') {
                this.details.title = ''
                this.details.item = {}
                this.isShowDetails = false
            } else if (type === 'CREATED' || type === 'UPDATE') {
                this.showFormDialog = false
            } else if (type === 'loading') {
                this.loadingDialog = false
                this.queryData();
            } else if (type === 'loading-error') {
                this.loadingDialog = false
            }
        },
        async saveBill(value) {
            this.loadingDialog = true
            this.isHtml = true
            this.loadingFinished = false
            if (value) {
                if (value.formType === 'CREATED') {
                    this.loadingMessage = `Creating new merchant <strong><i>${value.name}</i></strong>`
                    setTimeout(async () => {
                        const createResponse = await API.createBill(value)
                        console.log(createResponse)
                        this.loadingFinished = true
                        if (!createResponse || createResponse.error) {
                            this.isHtml = false
                            this.loadingMessage = createResponse.error
                        } else {
                            this.loadingMessage = `Successfully created merchant <strong><i>${value.name}</i></strong>`
                            this.showFormDialog = false
                            this.$refs.merchantForm.resetForm()
                        }
                    }, 2000);

                } else if (value.formType === 'UPDATE') {
                    this.loadingMessage = `Updating merchant <strong><i>${value.name}</i></strong>`
                    setTimeout(async () => {
                        const updateResponse = await API.updateBill(value)
                        this.loadingFinished = true
                        if (!updateResponse || updateResponse.error) {
                            this.isHtml = false
                            this.loadingMessage = updateResponse.error
                        } else {
                            this.loadingMessage = `Successfully updated merchant <strong><i>${value.name}</i></strong>`
                            this.showFormDialog = false
                            this.$refs.merchantForm.resetForm()
                        }
                    }, 2000);
                } else {
                    this.loadingMessage = "Invalid command"
                    this.loadingFinished = true
                }
            } else {
                this.loadingMessage = "Error processing data."
                this.loadingFinished = true
            }
        },
        viewBill(merchant) {
            console.log(merchant)
            this.details.title = merchant.name
            this.details.item = merchant
            this.isShowDetails = true
        },
        search(event, type) {
            this.query[type] = event
        },
        getToday() {
            const today = moment().format("yyyy-MM-DD")
            return today
        },
        setDefaultDateRange() {
            this.query.startIssueDate = ''
            this.query.endIssueDate = ''
            this.query.startDueDate = ''
            this.query.endDueDate = ''
            this.maxDate = moment().add(1, "days").format("yyyy-MM-DD")
            this.fromMaxIssue = moment().add(1, "days").format("yyyy-MM-DD")
            this.fromMaxDue = moment().add(1, "days").format("yyyy-MM-DD")
            this.toMinIssue = moment().format(this.query.startIssueDate)
            this.toMinDue = moment().format(this.query.startDueDate)
        },
        clearToDate(type) {
            if (type === 'issue') {
                this.query.endIssueDate = ''
                this.toDateMenuIssue = false
            } else {
                this.query.endDueDate = ''
                this.toDateMenuDue = false
            }
        },
        clearFromDate(type) {
            if (type === 'issue') {
                this.query.startIssueDate = ''
                this.fromDateMenuIssue = false
            } else {
                this.query.startDueDate = ''
                this.fromDateMenuDue = false
            }
        },
        clearSearch() {
            this.query = {
                // id: '',
                // name: '',
                // mobileNumber: '',
                // email: '',
                // merchantId: '',
                // enabled: '',
                // dateFrom: '',
                // dateTo: ''

                accountNumber: '',
                billingNumber: '',
                accountName: '',
                startIssueDate: '',
                endIssueDate: '',
                startDueDate: '',
                endDueDate: '',
            }
            this.queryData()
        }
    }
};
</script>

<style scoped>
.v-data-table {
    max-width: 100%
}
</style>
